import axios from "axios"
import { signInWithEmailAndPassword } from "firebase/auth"
import React from "react"
import { auth } from "../../../firebase-config"
import { submitContactsForm, submitGeolocationForm } from "../../service/forms-service"
import { ENABLE_ERROR_LOGGING, ENDPOINT_128 } from "../../settings/project-settings"

export default() => {
    const [currentUser, setCurrentUser] = React.useState("NOT LOGGED IN!")
    const [token, setToken] = React.useState("NOT LOGGED IN!")
    React.useEffect(()=>{
        auth.onAuthStateChanged(()=>{
            if( auth.currentUser ) setCurrentUser(auth.currentUser.uid)
            else setCurrentUser("failed to login")
            
            let anyAuth : any = auth
            if( anyAuth?.currentUser?.accessToken )
                setToken( anyAuth.currentUser.accessToken )
            
        })
        
    })

    return (
        <>
            <div> logged in as: {currentUser}</div>
            <div> logged in as: {JSON.stringify((auth as any)?.currentUser?.email)}</div>
            
            <QuickLogin >
                Login
            </QuickLogin>

            <QuickButton 
                token={token} 
                endpoint={ENDPOINT_128} 
                keyValue="get-dashboard"
                params={{ newValue : false }}
                onClick={()=>{
                    const analytics = {
                        entries: [
                            { value: 'city', id: '333417443' },
                            { value: 'state', id: '1641778804' },
                            { value: 'zipcode', id: '1263806622' },
                            { value: 'country', id: '1483646226' },
                            { value: 'latitude', id: '1640620501' },
                            { value: 'longitude', id: '527907479' }
                        ],
                        responderId: '1FAIpQLSfeXrgqlFp1SLij2I8-S07YZJJUEcQJQoSwc2YI4f9jQHv6jw',
                        formId: '1LMR9rCkllDJG924ePI3Nmjamh4VV4Q5ZoX9xOlq1WfE',
                        responderUri: 'https://docs.google.com/forms/d/e/1FAIpQLSfeXrgqlFp1SLij2I8-S07YZJJUEcQJQoSwc2YI4f9jQHv6jw/viewform',
                        url: 'https://docs.google.com/forms/d/1LMR9rCkllDJG924ePI3Nmjamh4VV4Q5ZoX9xOlq1WfE/edit?usp=drivesdk'
                    }
                    submitGeolocationForm(analytics)
                }}
                >
                send values to analytics
            </QuickButton>


            <QuickButton 
                token={token} 
                endpoint={ENDPOINT_128} 
                keyValue="get-dashboard"
                params={{ newValue : false }}
                onClick={()=>{
                       submitContactsForm({
                            entries: [
                                { value: 'name', id: '731458276' },
                                { value: 'phone', id: '1415393020' },
                                { value: 'email', id: '510678027' },
                                { value: 'notes', id: '1214066618' }
                            ],
                            responderId: '1FAIpQLSesiIj0KT43H2EtHEc_-bsz4D7OPH8a8-k7tQscynN-3MMYyw',
                            formId: '1NL3FUt_hHIsa1Sas4_0sZgceYog0OLqvJG3zHo_blPw',
                            responderUri: 'https://docs.google.com/forms/d/e/1FAIpQLSesiIj0KT43H2EtHEc_-bsz4D7OPH8a8-k7tQscynN-3MMYyw/viewform',
                            url: 'https://docs.google.com/forms/d/1NL3FUt_hHIsa1Sas4_0sZgceYog0OLqvJG3zHo_blPw/edit?usp=drivesdk'
                        },
                        {
                            "name" : "New Name",
                            "phone" : "New Phone",
                            "email" : "New Email",
                            "notes" : "New Notes"
                        }
                    )
                }}
            >
                send values to contacts
            </QuickButton>


            <br/>
            <br/>
            {token}
        </>
    )
}

const QuickLogin = ( props : { 
    children ?: any 
}) => {
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={ ()=>{
                signInWithEmailAndPassword(auth,"newuser-test@email.com", "password").then(( res )=>{
                    alert("success!")
                    if( ENABLE_ERROR_LOGGING ) console.log(res)
                })
            }}>
            {props.children}
        </button>
        
        </>
    )
}


const QuickButton = ( props : { 
    children ?: any, 
    keyValue : string,
    params ?: any
    token?: string,
    endpoint?:string,
    onClick ?: any, 
}) => {

    const CONFIG = {
        headers: { 
            Authorization: `Bearer ${props.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*'
        },crossDomain: true
    };

    
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={ props.onClick }>
            {props.children}
        </button>
        
        </>

    )
}


function getTemporaryToken (){
    return 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4ODI0YTI2ZjFlY2Q1NjEyN2U4OWY1YzkwYTg4MDYxMTJhYmU5OWMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdGVzdC01MDAtOTk5OSIsImF1ZCI6InRlc3QtNTAwLTk5OTkiLCJhdXRoX3RpbWUiOjE2Nzg5MjAwNDUsInVzZXJfaWQiOiJ6OHVuN21lMmRBU0RDOFo5Q3o4VVhKNzRPc2IyIiwic3ViIjoiejh1bjdtZTJkQVNEQzhaOUN6OFVYSjc0T3NiMiIsImlhdCI6MTY3ODkyMDA0NSwiZXhwIjoxNjc4OTIzNjQ1LCJlbWFpbCI6InRlc3RhcGlnYXRld2F5QHRlc3RpbmcuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInRlc3RhcGlnYXRld2F5QHRlc3RpbmcuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.VG7HrhFw5goK3q-hRGq_BK265VYIOC7eQ_tdJVx7yyGYqGojSGDr-Ug5WJdAGL3An9FxHnjS2G3ohl_UApEDWUx8YkD89ceglAr32fCvoc9PYPPsVPdFpjcJoYN2QEQavxkozT41uxZEO1GZp9Az8zjBMjtWL-6heI9G93I7E9owli0zltHFfUWpDUER9DINfniymr2PeeKWkpWz9cyQct_OigivokCmDfmJAhsW9gvuqA7Ku6I05NGK-OsjLkBSCgKwzzvHKCfIs5A0xUfN-mpbsFq0GiTD_mRmOWvYxz1kiiFX0V27MLGIjO8uRU5SYWhbG6kR_KfbXhqe2qmzQA'
}