import axios from "axios"
import { ENABLE_ERROR_LOGGING } from "../settings/project-settings";

export async function submitGeolocationForm( data : any ){
    await axios.get('https://ipapi.co/json/').then(async (response) => {
        let loc = response.data;
        
        let buffer : any = {
            country: loc.country_code,
            latitude: loc.latitude,
            longitude: loc.longitude,
            state : loc.region_code,
            zipcode : loc.postal,
            city : loc.city
        }

        if( ENABLE_ERROR_LOGGING ) console.log( buffer )
  
        const responderId       = data.responderId
        const baseURL           = `https://docs.google.com/forms/u/0/d/e/${responderId}/formResponse`
        const entries           = data.entries.map(( entry : any )=>{
            return {
                key : `entry.${ entry.id }`,
                value : entry.id + ':' + buffer[ entry.value.toLowerCase() ]
            }
        })
  
        
        const submissionURL = getGoogleFormSubmission(baseURL,entries)
        await fetch(submissionURL).catch(()=>{ if( ENABLE_ERROR_LOGGING ) console.log('submission attempted. Cannot verify if successful from here.') });
        if( ENABLE_ERROR_LOGGING ) console.log(entries)
        if( ENABLE_ERROR_LOGGING ) console.log(submissionURL)
       
    }).catch((error) => {if( ENABLE_ERROR_LOGGING ) console.log(error)} );
  }



export async function submitContactsForm( data : any , inputs : any ){
  
        
    let buffer : any = {
        name: inputs["name"],
        phone: inputs["phone"],
        email: inputs["email"],
        notes : inputs["notes"],
    }

    const responderId       = data.responderId
    const baseURL           = `https://docs.google.com/forms/u/0/d/e/${responderId}/formResponse`
    const entries           = data.entries.map(( entry : any  )=>{
        return {
            key : `entry.${ entry.id }`,
            value : entry.id + ':' + buffer[ entry.value.toLowerCase() ]
        }
    })
    
    const submissionURL = getGoogleFormSubmission(baseURL,entries)
    await fetch(submissionURL).catch(()=>{ if( ENABLE_ERROR_LOGGING ) console.log('submission attempted. Cannot verify if successful from here.') });
  
}


export const getGoogleFormSubmission = (
    url : string,
    entries : Array<{ key : string, value : string }>
) => {
   let urlcompile : string = url + "?"
   entries.forEach(( item : any, index : number )=>{ 
        urlcompile += (index === 0 ? "" : "&") + item.key + '=' + encodeURIComponent(item.value)
   })
   // return urlcompile
   return urlcompile
}




export const submitGeolocationFormV2 = async ( props : { analytics : any }) =>  {
    const VERSION : number = 2

    axios.get('https://ipapi.co/json/').then((response) => {
        let loc = response.data;
        
        let buffer : any = {
            country: loc.country_code,
            latitude: loc.latitude,
            longitude: loc.longitude,
            state : loc.region_code,
            zipcode : loc.postal,
            city : loc.city
        }
  
        if(props.analytics === undefined){if( ENABLE_ERROR_LOGGING ) console.log('ERROR: ANALYTICS IS UNDEFINED'); return }
        
  
        const analytics         = props.analytics
        const responderId       = analytics.responderId
        const baseURL : string  = `https://docs.google.com/forms/u/0/d/e/${responderId}/formResponse`
        const entries           = analytics.entries.map(( entry : any )=>{
            return {
                key : `entry.${ entry.id }`,
                value : entry.id + ':' + buffer[ entry.value.toLowerCase() ]
            }
        })
  
        const submissionURL : string = getGoogleFormSubmission(baseURL,entries)
        
        if( ENABLE_ERROR_LOGGING ) console.log("attempting submission...")
        fetch(submissionURL).catch(()=>{ if( ENABLE_ERROR_LOGGING ) console.log('submission attempted. Cannot verify if successful from here.') });
    
    }).catch((error) => {if( ENABLE_ERROR_LOGGING ) console.log(error)} );  
}


export const getSubmitGeolocationData = async () =>  {
    const VERSION : number = 1
    return await axios.get('https://ipapi.co/json/').then((response) => {
        let loc = response.data;
        let buffer : any = {
            country: loc.country_code,
            latitude: loc.latitude,
            longitude: loc.longitude,
            state : loc.region_code,
            zipcode : loc.postal,
            city : loc.city
        }
        return buffer
    }).catch((error) => {
        if( ENABLE_ERROR_LOGGING ) console.log(error);
        return false;
    });  
}


export const getSubmitContactsData = async (  inputs : any  ) =>  {
    const VERSION : number = 1
    let buffer : any = {
        name: inputs["name"],
        phone: inputs["phone"],
        email: inputs["email"],
        notes : inputs["notes"],
    }
    return buffer
}